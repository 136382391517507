/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
* file, You can obtain one at https://mozilla.org/MPL/2.0/. */

/* Controls are all the small buttons to the side or the top of a cell */

.cell-controls {
  text-align: center;
  height: 100%;
  position: relative;
}

.cell-controls-corner {
  grid-area: controls-left-above;
}

.cell-controls-above {
  grid-area: above;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cell-controls-left {
  text-align: center;
  height: 100%;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.5em;
}

.cell-controls-left-above {
  grid-area: margin-left-above;
}

.cell-controls-left-top {
  grid-area: margin-left-top;
}
.cell-controls-left-bottom {
  grid-area: margin-left-bottom;
}

.cell-controls .cell-controls-button.auto-hide {
  opacity: 0.2;
}

.cell-controls .cell-controls-button.cell-controls-button-language.auto-hide {
  opacity: 0.67;
}

.cell-container:hover .cell-controls-button.auto-hide,
starboard-cell:focus-within .cell-controls-button,
starboard-cell:focus .cell-controls-button {
  opacity: 1 !important;
}

/* .cell-container:hover .cell-controls-button {
    color: var(--controls-hidden-color);
} */

.cell-controls-button {
  background: transparent;
  color: var(--controls-color);
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  padding: 0px 4px;
  line-height: 1;

  border-radius: 4px;
  border: 1px solid #ffffff00;
  z-index: 1;

  transition: opacity 120ms ease-in-out;
  opacity: 1;
  display: flex;
}

.cell-controls-button-language {
  font-size: 10px;
  text-transform: uppercase;
}

.cell-controls-button:hover {
  color: var(--brand-color);
}
// .cell-controls-button:focus {
//     outline: none;
//     border: 1px solid #eee;
// }

.collapsed-cell-line {
  display: none;
  background-color: var(--cell-collapsed-line-color);
  height: 6px;
  flex-grow: 1;
  cursor: pointer;
  z-index: 1;
  transition: all 100ms ease-in-out;
}

.collapsed-cell-line:hover {
  filter: brightness(0.95);
  height: 8px;
}
