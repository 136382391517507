/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/. */

.cell-grid {
  display: grid;
  grid-template-columns:
    [full-width-start] auto [margin-left-start] var(--cell-margin-left)
    [margin-left-end content-start] minmax(
      auto,
      calc(min(var(--cell-base-width), 100% - var(--cell-margin-left) - var(--cell-margin-right)))
    )
    [content-end margin-right-start] var(--cell-margin-right) [margin-right-end] auto [full-width-end];
  grid-template-rows: 20px auto auto;
  grid-template-areas:
    "gutter-left-above margin-left-above above margin-right-above gutter-right-above"
    "gutter-left-top margin-left-top top margin-right-top gutter-right-top"
    "gutter-left-bottom margin-left-bottom bottom margin-right-bottom gutter-right-bottom";
}

.line-grid {
  display: grid;
  grid-template-columns:
    [full-width-start] auto [margin-left-start] var(--cell-margin-left)
    [margin-left-end content-start] minmax(
      auto,
      calc(min(var(--cell-base-width), 100% - var(--cell-margin-left) - var(--cell-margin-right)))
    )
    [content-end margin-right-start] var(--cell-margin-right) [margin-right-end] auto [full-width-end];
}

starboard-cell:focus {
  outline: none;
}

.display-when-collapsed {
  display: none !important;
}

starboard-cell:not(:focus):not(:focus-within).property-collapsed {
  .cell-top,
  .cell-bottom,
  .cell-controls-left-top,
  .cell-controls-left-bottom,
  .cell-gutter-top,
  .cell-gutter-bottom {
    display: none;
  }

  * {
    .collapsed-cell-line,
    .display-when-collapsed {
      display: flex !important;
    }
  }
}

.property-bottom_hidden {
  .cell-bottom,
  .cell-controls-left-bottom,
  .cell-gutter-bottom {
    display: none;
  }
}

.property-top_hidden {
  .cell-top,
  .cell-controls-left-top,
  .cell-gutter-top {
    display: none;
  }
}

.cell-top {
  grid-area: top;
  min-height: 24px;
  padding-bottom: 0.5em;
}

.cell-bottom {
  grid-area: bottom;
  padding-bottom: 0.5em;
}

.cell-output-html {
  overflow-x: auto;
}
