/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/. */

starboard-insertion-line {
  &.insertion-line-top {
    // top: 0%;
    grid-row: above;
  }

  &.insertion-line-bottom {
    top: 100%;
    grid-row: bottom;
  }

  left: 0;
  right: 0;
  height: 0;
  grid-column: margin-left-start / margin-right-end;

  position: relative;

  .hover-area {
    display: flex;
    align-items: center;

    position: absolute;
    height: 8px;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;

    &:hover .insert-button {
      color: var(--insertion-line-plus-color);
    }
  }

  .button-container {
    position: relative;
    width: var(--cell-margin-left);
    height: 100%;
    font-size: 10px;
    z-index: 2;
  }

  .button-container:nth-child(2) {
    opacity: 0;
  }

  .button-container:nth-child(2):focus-within {
    opacity: 1;
    background-color: #ffffffaa;
  }

  .button-container:nth-child(2):hover {
    opacity: 1;
    background-color: #ffffffaa;
  }

  .insert-button.plus {
    right: -11px;
    font-size: 16px;
    width: 28px; // <6 pix padding, 22 pix button (50% of that is 11px)
  }

  .insert-button {
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    transition: all 150ms ease-in-out;
    position: absolute;
    padding: 0;
    padding-left: 6px;
    background-color: transparent;
    color: transparent;

    font-size: 12px;

    border: 0;
    border-radius: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .insert-button:hover {
    filter: brightness(0.6);
  }

  &:hover .content-line {
    background-color: var(--insertion-line-color);
  }

  .content-line {
    transition: all 150ms ease-in-out;
    position: absolute;
    top: 50%;
    background-color: transparent;
    transform: translateY(-50%);
    left: calc(var(--cell-margin-left) + 12px);
    right: calc(var(--cell-margin-right));

    grid-column: content-start / content-end;
    height: 2px;
  }
}

/* Makes the current hovered cell + buttons above visible only, except for the first cell. */
starboard-cell:not(:first-of-type, :hover, :focus-within) .insertion-line-top {
  display: none;
}

starboard-cell:focus-within * .insert-button,
starboard-cell:hover * .insert-button {
  color: var(--insertion-line-plus-color);
}

starboard-cell-type-picker {
  z-index: 100;
}
