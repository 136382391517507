$text-strong: #222;
$weight-semibold: 600;
$weight-bold: 700;
$border: #666;
$background: #fff;

$content-heading-color: $text-strong !default;
$content-heading-weight: $weight-bold !default;
$content-heading-line-height: 1.125 !default;

$content-blockquote-background-color: var(--blockquote-background-color) !default;
$content-blockquote-padding: 1.25em 1.5em !default;

$content-pre-padding: 1.25em 1.5em !default;

$content-table-cell-padding: 0.5em 0.75em !default;
$content-table-cell-heading-color: $text-strong !default;
$content-table-head-cell-color: $text-strong !default;
$content-table-foot-cell-color: $text-strong !default;

body {
  flex: 1;

  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: var(--content-line-height);
  color: var(--foreground-color);
  font-family: var(--font-sans);
  font-size: var(--content-font-size);
  letter-spacing: -0.01em;
  word-wrap: break-word;
}

// Block
address,
  article,
  aside,
  blockquote,
  // dd,
  // dl,
  // dt,
  fieldset,
  figure,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  // li,
  nav,
  // ol,
  p,
  pre,
  table,
  tfoot,
  // ul,
  video {
  margin-top: var(--block-spacing-top);
  margin-bottom: var(--block-spacing-bottom);

  /* first element has no top margin */

  &:first-child {
    margin-top: unset;
  }
  &:last-child {
    margin-bottom: unset;
  }
}

ul,
ol,
dl {
  margin-bottom: var(--block-spacing-bottom);
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}

ul ul {
  margin-top: 0;
  margin-bottom: 0;
}

li > p {
  margin-top: var(--font-size);
}

/* hr */

hr:first-child {
  margin-top: calc(var(--hr-thickness) / -2);
  margin-bottom: calc(var(--content-line-height) - calc(var(--hr-thickness) / 2));
}

hr:last-child {
  margin-top: calc(var(--content-line-height) - calc(var(--hr-thickness) / 2));
  margin-bottom: calc(var(--hr-thickness) / -2);
}

hr,
hr:only-child {
  border: none;
  background: var(--hr-color);
  height: var(--hr-thickness);
  margin-top: calc(var(--content-line-height) - calc(var(--hr-thickness) / 2));
  margin-bottom: calc(var(--content-line-height) - calc(var(--hr-thickness) / 2));
}

* + hr:last-child {
  /* hr following block has hrThickness removed from its top spacing */
  margin-top: calc(var(--hr-thickness) / -2);
}

hr:not(:first-child) {
  /* note: collapses with preceeding block bottom margin */
  margin-top: var(--content-line-height);
  margin-bottom: calc(var(--content-line-height) - var(--hr-thickness));
}

// Inline
li + li {
  margin-top: 0.25em;
}

// Block
p,
  // dl,
  // ol,
  // ul,
  blockquote,
  pre,
  table {
  &:not(:last-child) {
    margin-bottom: 0.8em;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $content-heading-color;
  font-weight: $content-heading-weight;
  line-height: $content-heading-line-height;
  margin-bottom: var(--font-size);

  &:not(:first-child) {
    margin-top: var(--content-line-height);
  }
}

// Github-style h1 and h2 bottom border
h1,
h2 {
  margin-bottom: calc(var(--font-size) * 1.5);
  //Github-style h1 and h2 bottom border
  // padding-bottom: .3em;
  // border-bottom: 1px solid var(--border-color-secondary);
}

h1 {
  font-size: var(--h1-size);
  letter-spacing: -0.05em;
}

h2 {
  font-size: var(--h2-size);
  letter-spacing: -0.03em;
}

h3 {
  font-size: var(--h3-size);
  letter-spacing: -0.02em;
}

h4 {
  font-size: var(--h4-size);
  letter-spacing: -0.012em;
}

h5 {
  font-size: 0.875em;
}

h6 {
  font-size: 0.875em;
  color: var(--text-color-tertiary);
}

blockquote {
  color: var(--blockquote-text-color);
  background-color: $content-blockquote-background-color;
  border-left: 5px solid var(--blockquote-border-left-color);
  padding: $content-blockquote-padding;
}

figure {
  margin-left: 2em;
  margin-right: 2em;
  text-align: center;

  &:not(:first-child) {
    margin-top: 2em;
  }

  &:not(:last-child) {
    margin-bottom: 2em;
  }

  img {
    display: inline-block;
  }

  figcaption {
    font-style: italic;
  }
}

pre {
  -webkit-overflow-scrolling: touch;

  overflow-x: auto;
  padding: $content-pre-padding;
  white-space: pre;
  word-wrap: normal;
}

sup,
sub {
  font-size: 75%;
}

table {
  max-width: 100%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;

  td,
  th {
    border: 1px solid var(--border-color);
  }

  td,
  th {
    // border: $content-table-cell-border;
    // border-width: $content-table-cell-border-width;
    padding: $content-table-cell-padding;
    vertical-align: top;
  }

  th {
    color: $content-table-cell-heading-color;
    font-weight: 500;

    &:not([align]) {
      text-align: inherit;
    }
  }

  thead {
    td,
    th {
      color: $content-table-head-cell-color;
    }
  }

  tfoot {
    td,
    th {
      color: $content-table-foot-cell-color;
    }
  }

  // tbody {
  //   tr {
  //     &:last-child {
  //       td,
  //       th {
  //         border-bottom-width: 0;
  //       }
  //     }
  //   }
  // }
}

.tabs {
  li + li {
    margin-top: 0;
  }
}

ol,
ul {
  padding-left: 2em;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-top: 0;
  margin-bottom: 0;
}

li {
  word-wrap: break-all;
}

li > p {
  margin-top: 16px;
}

li + li {
  margin-top: 0.25em;
}

dl {
  padding: 0;
}

dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}

dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}

pre,
code {
  font-size: 1em;
  font-family: var(--font-mono);
  background-color: var(--code-background-color);
}

code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
}

pre > code {
  padding: 0;
  margin: 0;
  font-size: 100%;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

a:visited {
  color: var(--link-color);
}

a:hover {
  text-decoration: underline;
}

details {
  display: block;
}

summary {
  display: list-item;
}

img {
  max-width: 100%;
  box-sizing: initial;
  background-color: #fff;
}

img[align="right"] {
  padding-left: 20px;
}

img[align="left"] {
  padding-right: 20px;
}
