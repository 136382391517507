/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/. */

starboard-console-output .starboard-console-output-inner {
  line-height: 1.2;
  background-color: #242424;
  overflow-x: hidden;

  border-radius: var(--border-radius);
}
